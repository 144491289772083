import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SET_MERGE_ERRORS]: (
    state,
    { errors, perPage, totalItems, totalPages }
  ) => {
    state.mergeErrors = errors
    state.mergeErrorsPerPage = perPage
    state.mergeErrorsTotalItems = totalItems
    state.mergeErrorsTotalPages = totalPages
  },

  [mutationTypes.SET_JOBS_STATS]: (state, payload) => {
    state.jobsStats = payload
  },
  [mutationTypes.SET_JOBS]: (
    state,
    { jobs, perPage, totalItems, totalPages }
  ) => {
    state.jobs = jobs
    state.jobsPerPage = perPage
    state.jobsTotalItems = totalItems
    state.jobsTotalPages = totalPages
  },

  [mutationTypes.SET_RESOLVED_STATS]: (state, payload) => {
    state.resolvedStats = payload
  },

  [mutationTypes.SET_MERGED_DUPESETS_STATS]: (state, payload) => {
    state.mergedDupeSetsStats = payload
  },
  [mutationTypes.SET_MERGED_DUPESETS]: (
    state,
    { dupeSets, fields, perPage, totalItems }
  ) => {
    state.mergedDupeSets = dupeSets
    state.mergedDupeSetsFields = fields
    state.mergedDupeSetsPerPage = perPage
    state.mergedDupeSetsTotalItems = totalItems
  },

  [mutationTypes.SET_DUPE_SETS_TO_REVIEW_STATS]: (state, payload) => {
    state.dupeSetsToReviewStats = payload
  },
  [mutationTypes.SET_DUPE_SETS_TO_REVIEW]: (
    state,
    { dupeSets, perPage, totalItems }
  ) => {
    state.dupeSetsToReview = dupeSets
    state.dupeSetsToReviewPerPage = perPage
    state.dupeSetsToReviewTotalItems = totalItems
  },

  [mutationTypes.SET_BULK_DUPE_SETS]: (
    state,
    { dupeSets, stats, perPage, totalItems }
  ) => {
    state.bulkDupeSets = dupeSets
    state.bulkDupeSetsStats = stats
    state.bulkDupeSetsPerPage = perPage
    state.bulkDupeSetsTotalItems = totalItems
  },

  [mutationTypes.SET_BULK_DUPE_SETS_STATS]: (state, { stats }) => {
    state.bulkDupeSetsStats = stats
  },

  [mutationTypes.SET_AUTOMERGES_TO_REVIEW_STATS]: (state, payload) => {
    state.autoMergesToReviewStats = payload
  },
  [mutationTypes.SET_AUTOMERGES_TO_REVIEW]: (
    state,
    { dupeSets, perPage, totalItems, isBulkMergeEnabled }
  ) => {
    state.autoMergesToReview = dupeSets
    state.autoMergesToReviewPerPage = perPage
    state.autoMergesToReviewTotalItems = totalItems
    state.isBulkMergeEnabled = isBulkMergeEnabled
  },

  [mutationTypes.SET_FILTER_FIELDS]: (state, payload) => {
    state.filterFields = payload
  },
  [mutationTypes.SET_FILTER_TEXT_OPERATORS]: (state, payload) => {
    state.filterTextOperators = payload
  },
  [mutationTypes.SET_FILTER_DATE_OPERATORS]: (state, payload) => {
    state.filterDateOperators = payload
  },
  [mutationTypes.SET_FILTER_NUM_OPERATORS]: (state, payload) => {
    state.filterNumOperators = payload
  },

  [mutationTypes.SET_FILTER_OPTIONS_STATES]: (state, payload) => {
    state.filterOptions.state = payload
  },
}
