import { entityColors, roleColors } from '@/api/dupe-sets/dupe-sets'

export default {
  dupeSetsToReviewStatsByEntity({ dupeSetsToReviewStats }) {
    return aggregateCountByProperty(
      dupeSetsToReviewStats,
      'entity_name',
      entityColors
    )
  },
  dupeSetsToReviewCount({ dupeSetsToReviewStats }) {
    return countStats(dupeSetsToReviewStats)
  },
  dupeSetsToReviewTableHeaders({ tableHeaders }) {
    return tableHeaders.filter((el) => el.isInDupeSetsToReviewTable)
  },

  autoMergesToReviewStatsByEntity({ autoMergesToReviewStats }) {
    return aggregateCountByProperty(
      autoMergesToReviewStats,
      'entity_name',
      entityColors
    )
  },
  autoMergesToReviewCount({ autoMergesToReviewStats }) {
    return countStats(autoMergesToReviewStats)
  },
  autoMergesToReviewTableHeaders({ tableHeaders }) {
    return tableHeaders.filter((el) => el.isInAutoMergesToReviewTable)
  },

  mergedDupeSetsStatsByRole({ mergedDupeSetsStats }) {
    return aggregateCountByProperty(
      mergedDupeSetsStats,
      'role_name',
      roleColors
    )
  },
  mergedDupeSetsCount({ mergedDupeSetsStats }) {
    return countStats(mergedDupeSetsStats)
  },
  mergedDupeSetsTableHeaders({ tableHeaders }) {
    return tableHeaders.filter((el) => el.isInMergedDupeSetsTable)
  },
  jobsCount({ jobsStats }) {
    if (!jobsStats) return 0

    return jobsStats.reduce((acc, stat) => acc + stat.count, 0)
  },
}

const countStats = (stats) => {
  if (!stats) return 0

  const countIndex = stats.attributes.findIndex((el) => el === 'count')

  return stats.values.reduce((acc, stat) => acc + stat[countIndex], 0)
}

// if stats is:
//
// {
//   attributes: ['entity_name', 'role_name', 'auto_merge_state', 'count'],
//   values: [
//     ['Lead', 'Admin', 'do_not_auto_merge', 161],
//     ['Account', 'Admin', 'do_not_auto_merge', 79],
//     ['Account and Lead', 'Admin', 'do_not_auto_merge', 15],
//     ['Account', 'Admin', 'waiting_for_approval', 1],
//     ['Account and Lead', 'Admin', 'waiting_for_approval', 1]
//   ]
// }
// and property is 'entity_name'
//
// it returns:
// [
//   { entity_name: 'Lead', count: 161 },
//   { entity_name: 'Admin', count: 80 },
//   { entity_name: 'Account and Lead', count: 16 },
// ]
const aggregateCountByProperty = (stats, property, colors) => {
  if (!stats) return []

  const propertyIndex = stats.attributes.findIndex((el) => el === property)
  const countIndex = stats.attributes.findIndex((el) => el === 'count')

  // Creates the following intermediary object
  // {
  //   'Lead': 161,
  //   'Admin': 80,
  //   'Account and Lead': 16
  // }
  const countByProperty = stats.values.reduce((acc, currentStat) => {
    return {
      ...acc,
      [currentStat[propertyIndex]]:
        currentStat[countIndex] + (acc[currentStat[propertyIndex]] || 0),
    }
  }, {})

  return Object.keys(countByProperty).map((key) => {
    return {
      label: key,
      count: countByProperty[key],
      color: colors[key],
    }
  })
}
